import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Divider} from 'wix-ui-tpa/Divider'
import {Text} from 'wix-ui-tpa/Text'
import {getPreliminaryInvoice, getTaxConfig} from '@wix/wix-events-commons-statics'
import {getTicketPriceText} from '../../../../../selectors/order-success'
import {classes} from './whole-element-reservation.st.css'
import {WholeElementReservationProps} from '.'

export const WholeElementReservation = ({
  place: {places, reservationOptions, ticket},
  basketView,
  inDescription,
  showAccessibilityMode,
  event,
}: WholeElementReservationProps) => {
  const {t} = useTranslation()

  if (!reservationOptions?.reserveWholeElement) {
    return null
  }

  return (
    <div className={basketView || inDescription || showAccessibilityMode ? classes.container : ''}>
      <Divider className={classes.divider} />
      <Text className={classes.tableReservation}>
        {t('seating_tableReservation', {
          price: getTicketPriceText(
            getPreliminaryInvoice([ticket], {[ticket.id]: {quantity: places.length}}, getTaxConfig(event)).grandTotal,
            t,
          ),
        })}
      </Text>
    </div>
  )
}
